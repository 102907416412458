import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CVertCard,
  CDefinition,
  LContact,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'ACCESS',
            sub: 'ホテル発 直行バスのご案内',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/bus/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/bus/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '当ホテルについて',
                path: '/about/',
              },
              {
                label: 'アクセス',
                path: '/about/access/',
              },
            ],
            current: {
              label: 'ホテル発 直行バスのご案内',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <h2 className="c_headingLv2">空港リムジンバス</h2>
          <CDefinition
            exClass="u_mbMedium"
            data={[
              {
                title: '羽田空港・成田空港',
                text: (
                  <>
                    詳細は
                    <Link
                      to="/about/access/airplanemenu/"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      こちら
                    </Link>
                    をご確認ください。
                  </>
                ),
              },
            ]}
          />
          <h2 className="c_headingLv2">御殿場プレミアム・アウトレット®</h2>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '時刻表',
                text: (
                  <>
                    （行き）8:30 ホテル発　→　10:15 着<br />
                    （帰り）16:15 発　→　18:00 ホテル着
                  </>
                ),
              },
              {
                title: '運賃',
                text: (
                  <>
                    乗車日によって異なりますので、詳しくはベルキャプテンデスクにお問合せください。
                  </>
                ),
              },
              {
                title: <>乗車場所</>,
                text: <>ホテル正面玄関</>,
              },
              {
                title: '最終運行日',
                text: (
                  <>
                    ビル大規模修繕工事による営業一時休止にともない、3月31日（月）をもちましてホテル正面玄関のバス停留所を休止いたします。
                    <br />
                    <ul className="c_noteList">
                    <li>
                      8:30 ホテル発便が最終となります。
                      </li> 
                       <li>
                      18:00 ホテル着便（復路）はホテル正面玄関のバス停留所に到着いたしますが、ホテル館内への立入りはできません。
                      </li>
                    </ul>
                  </>
                ),
              }
            ]}
          />
          <ul className="c_noteList u_mbMedium">
            <li>
              完全予約制。ご宿泊のお客様は、前日16:00までにベルキャプテンデスクでご予約ください。
            </li>
            <li>
              道路事情等により遅延する場合もありますので、予めご了承ください。
            </li>
          </ul>
          <h2 className="c_headingLv2">横浜・八景島シーパラダイス</h2>
          <CDefinition
            data={[
              {
                title: '時刻表',
                text: (
                  <>
                    <p className="u_mb30">
                      平日
                      <br />
                      9:40　ホテル発　→　10:15 八景島着
                      <br />
                      10:40 ホテル発　→　11:15 八景島着
                      <br />
                      15:00 八景島発　→　15:35 ホテル着
                      <br />
                      16:00 八景島発　→　16:35 ホテル着
                    </p>
                    <p>
                      土・日・祝日
                      <br />
                      9:40　ホテル発　→　10:15 八景島着
                      <br />
                      10:40 ホテル発　→　11:15 八景島着
                      <br />
                      15:00 八景島発　→　15:35 ホテル着
                      <br />
                      17:00 八景島発　→　17:35 ホテル着
                    </p>
                  </>
                ),
              },
              {
                title: '運賃',
                text: (
                  <>
                    大人：片道 1,000円
                    <br />
                    子供：片道　500円
                  </>
                ),
              },
              {
                title: <>乗車場所</>,
                text: <>ホテル正面玄関</>,
              },
              {
                title: '最終運行日',
                text: (
                  <>
                    ビル大規模修繕工事による営業一時休止にともない、3月31日（月）をもちましてホテル正面玄関のバス停留所を休止いたします。
                    <br />
                    <ul className="c_noteList">
                      <li>10:40ホテル発便が最終となります。
                      </li>
                      <li>15:35／16:35 ホテル着便（復路）もバスは運行いたしますが、<br className='u_sp'/>ホテル正面玄関のバス停留所は通過いたします。
                      </li>
                    </ul>
                  </>
                ),
              }
            ]}
          />
          <ul className="c_noteList u_mbMedium">
            <li>完全予約制。ご宿泊のお客様は、ベルキャプテンデスクでご予約ください。</li>
            <li>
              道路事情等により遅延する場合もありますので、予めご了承ください。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LContact
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
